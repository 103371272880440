import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Helmet } from "react-helmet"

import Containers from "../components/containers"
import ScrollArrow from "../components/scroll-arrow"

const DefaultTemplate = props => {
  let page = props.data ? props.data.page : props.page

  const bodyClass =
    props.bodyClass || `stretched page-${page.slug.split(".")[0]}`

  return (
    <Layout footerContact={(page.properties || {})["footer_contact"]}>
      <SEO title={page.title} keywords={[`gatsby`, `application`, `react`]} />

      <Helmet bodyAttributes={{ class: bodyClass }} />

      <Containers data={[page.containers[0]]} />
      <ScrollArrow />

      <section id="content">
        <div className="content-wrap">
          <Containers data={page.containers.slice(1)} />
        </div>
      </section>
    </Layout>
  )
}

DefaultTemplate.defaultProps = {
  page: {},
  bodyClass: null,
}

export const query = graphql`
  query PageQuery($slug: String!) {
    page: graphCmsPage(slug: { eq: $slug }) {
      ...GraphCmsPage
    }
  }
`

export default DefaultTemplate
